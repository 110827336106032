import React from 'react'
import {Link} from 'react-router-dom'

const Navbar = () =>{
    return(
        <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">Spring 2019</Link>
                    </li>
                    <li className="nav-item">
                        <Link className ="nav-link" to="/">Winter 2018</Link>
                    </li>
                </ul>
        </div>
    )
}

export default Navbar
